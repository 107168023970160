$primary-color: #0a494e;
$background-color: #fafafc;
$border-color: #aec5dc;
$border-focused: #38bdad;

$neutral-grey: #595959;

$date-picker-primary: #3a66ff;
$date-picker-secondary: #cfdeee;

$border-radius: 8px;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.form-group-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.rootContainer {
  margin-bottom: 64px;
  min-width: 320px;
}

.MuiInputBase-root {
  & fieldset {
    border-color: $border-color !important;
  }
  &.Mui-focused fieldset {
    border-color: $border-focused !important;
  }
  &.Mui-error fieldset {
    border-color: red !important;
  }
}

.wellinks-input-bold {
  & fieldset {
    border: 2px solid $neutral-grey !important;
  }
  &.Mui-error fieldset {
    border-color: red !important;
  }
}

.MuiButtonBase-root.wl-button {
  font-size: 16px;
  font-weight: 700;
  background-color: #0a494e !important;
  color: #ffffff;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.MuiButtonBase-root.wl-button.btn-secondary {
  background-color: #ffffff !important;
  color: #0a494e;
  border: 1px solid #0a494e;
}

.MuiButtonBase-root.wl-button.Mui-disabled {
  background-color: $neutral-grey !important;
  color: #ffffff;
}

.MuiDialog-paper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: 8px;
}

.MuiDialogTitle-root.wl-modal-title,
.wl-modal-title {
  line-height: 1.5em;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.wl-modal-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.MuiDateCalendar-root {
  background-color: #ffffff;
  border-radius: $border-radius;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root {
  background: transparent !important;
}

.wl-date-picker
  .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  color: #000000;
  background-color: $date-picker-secondary;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected {
  background-color: $date-picker-primary !important;
  color: #ffffff !important;
}

.MuiPickersDay-root.Mui-selected.MuiPickersDay-hiddenDaySpacingFiller {
  background: none;
}

.MuiPickersDay-today {
  text-decoration: underline !important;
  text-decoration-thickness: 1.5px !important;
  text-underline-offset: 4px !important;
  border: none !important;
}

.datePickerLegendItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.rightAppointmentPanel {
  max-width: 490px;
}

.appointmentDateContainer {
  text-align: center;
  padding: 16px;
  background-color: $date-picker-secondary;
  border-radius: $border-radius;
  max-width: 490px;
  margin: auto;
  min-height: 334px;
  position: relative;
}

.appointmentDateContainerTail {
  position: absolute;
  top: calc(40% - 15px);
  left: -28px;
  border: 15px solid transparent;
  border-right-color: $date-picker-secondary;
}

.appointmentDateContainerTailTop {
  position: absolute;
  left: calc(50% - 15px);
  top: -28px;
  border: 15px solid transparent;
  border-bottom-color: $date-picker-secondary;
}

.apptPickerTitle {
  font-size: 20px;
  font-weight: 600;
}

.apptPickerDescription {
  color: $neutral-grey;
  font-weight: 500;
  font-size: 13px;
}

.apptPickerTimeOfDay {
  color: #424242;
  font-weight: 600;
  font-size: 16px;
}

.apptPickerInfo {
  color: #424242;
  font-size: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: right;
  margin-top: 4px;
}

.avatarContainer {
  border: 2px solid #cfdeee;
  border-radius: 50%;
  background-color: $primary-color;
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.schedulingIcon {
  width: 20px;
  margin-right: 14px;
}

.calendarIcon {
  width: 40px;
  margin-right: 14px;
}

.schedulingRow {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.schedulingTitleContainer {
  margin-left: 80px;
  margin-right: 80px;
}

.schedulingContainer {
  margin-top: 30px;
}

.MuiButtonBase-root.MuiChip-root.apptPickerChip {
  margin-bottom: 6px;
  margin-right: 6px;
  background-color: #ffffff;
}

.MuiButtonBase-root.MuiChip-root.apptPickerChip.apptSelectedChip {
  background-color: $date-picker-primary;
  color: #ffffff !important;
}
