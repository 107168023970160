.agreementLink {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 25px;
  line-height: 140%;
  /* or 22px */
  text-align: right;
  color: #0a05ff !important;
}

.agreementTitle {
  /* App/Title/Title Large */
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  /* identical to box height, or 35px */
  color: #000000;
}

.agreementSubtitle {
  /* App/Title/Title Large */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 35px */
  color: #000000;
}
