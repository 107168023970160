footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #12253e;

  .container {
    display: flex;
    flex-direction: column;
    max-width: 1152px;
    color: #62baad;
    font-size: 20px;
    text-align: center;
    width: calc(100% - 48px);
    margin: 0 auto;
    margin-bottom: 0px;
    // padding: 0 24px;
  }

  .white-footer {
    background-color: #fff;
    color: #000;
  }

  .footer-top_component {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);

    .dark-footer {
      border-bottom-color: rgba(0, 0, 0, 0.14);
    }
  }

  .footer-top_heading {
    margin-top: 0px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    line-height: 130%;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    .footer-black {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .footer-top_links-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-top_link {
    margin-right: 28px;
    transition: opacity 200ms ease;
    color: #fff;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }

    &.footer-black {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .footer-bottom_component {
    padding: 36px 0 20px 0;
    display: flex;
    flex-direction: row;
  }

  .footer-bottom_wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
    width: 100%;

    .lp {
      justify-content: flex-start;
    }
  }

  .footer-bottom_icon {
    margin-right: 50px;

    .lp {
      margin-right: 30px;
    }
  }

  .footer-bottom_copyright-text {
    padding-right: 20px;
    border-radius: 0px;
    background-color: #12263f;
    color: #fff;
    font-size: 18px;
    line-height: 150%;
    font-weight: 400;
    letter-spacing: 0.01em;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;

    .footer-black {
      background-color: transparent;
      color: #000;
    }
  }

  .footer-logo {
    height: 5rem;
    margin-left: 3rem;
  }

  .footer-bottom-wrap {
    display: flex;
    // margin-right: 20px;
    align-items: center;
    flex: 1;
  }

  .footer-bottom-wrap.lp {
    margin-right: 0px;
    flex: 0 auto;
  }

  .contact-us_all-contacts {
    display: -ms-grid;
    display: grid;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 16px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
  }

  .contact-us_contact-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-us_icon {
    margin-bottom: 10px;
  }

  .contact-us_text {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.01em;
  }

  .contact-us_text {
    .footer-black {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .contact-us_link-block {
    text-decoration: none;
  }

  .contact-us_social-icon-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-us_all-social {
    display: grid;
    margin-top: 10px;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .contact-us_social-icon {
    width: 40px;
    height: 40px;
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.5;
    }
  }

  .section-footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #12253e;

    .white-footer {
      background-color: #fff;
      color: #000;
    }
  }

  @media screen and (max-width: 991px) {
    .footer-bottom-wrap {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 40px;
    }

    .footer-top_links-wrap {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .footer-top_link {
      margin-right: 0px;
    }

    .footer-bottom_wrap {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }

    .footer-bottom_copyright-text {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      text-align: left;
    }

    .footer-logo {
      margin-left: 0rem;
    }
  }

  @media screen and (max-width: 479px) {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 24px;
    }

    .footer-top_component {
      padding-bottom: 10px;
    }

    .footer-top_heading {
      text-align: center;
    }

    .footer-top_links-wrap {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .footer-top_link {
      margin-bottom: 15px;
    }

    .footer-bottom_wrap {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }

    .footer-bottom_icon {
      margin-bottom: 32px;
    }

    .footer-bottom_component {
      flex-direction: column;
      align-items: flex-start;
    }

    .contact-us_all-contacts {
      grid-column-gap: 20px;
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      display: flex;
      flex-direction: column;
    }

    .contact-us_contact-item {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .contact-us_icon {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .contact-us_social-media-wrap {
      width: 100%;
    }

    .contact-us_all-social {
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .contact-us_social-icon {
      width: 40px;
      height: 40px;
    }
  }
}
