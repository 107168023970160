.agreementLink {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px;
  line-height: 140%;
  text-align: right;
  color: #0a05ff !important;
}

.agreementTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  color: #000000;
}

.agreementSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  color: #000000;
}

.helpBtnIcon {
  color: #f7a525;
}

.helpBtn {
  border: 2px solid #f7a525 !important;
  border-radius: 40px !important;
}

.tosLinks {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  color: #0a05ff !important;
}

.agreementsLink {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  color: #0a05ff !important;
}

h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */

  // display: flex;
  // align-items: center;

  color: #000000;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  /* Accent/Destructive */
  color: #b02a2b;
}

.errorPadding {
  padding-left: 8px;
}

.instructionalText {
  // width: 273px;
  // height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  /* Neutrals/Grey 60 */
  color: #595959;
}

.fieldText {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  // display: flex;
  // align-items: center;

  /* Neutrals/Grey 90 */
  color: #202828;
}

.baseFieldBox {
  box-sizing: border-box;
  position: relative;
  width: 379px;
  height: 421px;
  border: 1px dashed #7b61ff;
  border-radius: 5px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row > button {
  margin-left: 4px;
  margin-right: 8px;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: "Courier New", Courier, monospace;
}

.button {
  appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.wl-button {
  padding: 10px 16px;
  gap: 8px;
  width: 130px;
  height: 43px;
  background: #0a494e;
  border-radius: 61px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.submit-btn-row {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 0px;
  gap: 16px;
}

.textbox {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 4px;
}

.button:hover,
.button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  composes: button;
  position: relative;
}

.asyncButton:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}

.form {
  text-align: left;
  margin-bottom: 24px;
}

.container {
  padding: 24px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 8px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.titleHeaderContainer {
  display: flex;
}

.titleMedium {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

.secondSectionHeader {
  margin-bottom: 24px;
}

.titleContent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #424242;
}

.formlabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.checkboxTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  font-weight: 600;
}

.line {
  border: 0.5px solid #000000;
}

.checkboxTitle > span {
  font-weight: normal;
}

.checkboxSubtitle {
  font-size: 15px;
}

.titleContentSuggestion {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.FormControl {
  margin-right: 25px;
}

.IndentedFormControl {
  margin-left: 32px;
}

.timepickercontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  float: left;
}

.timepicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 24px;
  width: 502px;
  height: 338px;
  float: left;

  /*position: absolute;
    
    left: 38px;
    top: 300px; */

  /* Blues/Blue 10 */

  background: #cfdeee;
  /* Card Shadow */

  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
}

.trianglepointer {
  width: 0px;
  height: 0px;
  margin: 0 auto;

  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 50px solid #cfdeee;
}

.checkboxGroup {
  margin-top: 5px;
  max-height: 22px;
}

.infoBox {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: start;
}

.patientExistsBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: start;
}

.infoIcon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
  margin-top: 4px;
}

.confirmationContainer {
  padding: 0px;
  background: #fafafc;
}

.confirmationDescription {
  color: #595959;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
}

.confirmationTitleContent {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22.4px;
  letter-spacing: 0em;
  text-align: left;
  color: #424242;
}

.ineligibleMessage {
  font-family: "Rene bieder galanogrotesque";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #000000 !important;
}
